var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cart-table", attrs: { role: "table" } },
    [
      !_vm.items.length
        ? _c("div", [_c("p", [_vm._v("Your cart is empty!")])])
        : _vm._l(_vm.items, function (item) {
            return _c(
              "div",
              { key: item._id },
              [
                _c("cart-item", {
                  attrs: { item: item },
                  on: {
                    remove: function ($event) {
                      return _vm.$emit("remove", item)
                    },
                  },
                }),
              ],
              1
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }