var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cart-footer" },
    [
      _c("div", [
        _vm._v(
          " Get more bang for your buck. Sign up for a subscription and get 200 download credits per month! "
        ),
      ]),
      _c("v-btn", { attrs: { outlined: "", href: "/tiers" } }, [
        _vm._v("Get Core"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }