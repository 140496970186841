var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cart-container" },
    [
      _c("section", { staticClass: "cart dn-container" }, [
        _c(
          "div",
          {},
          [
            _c("back-button", { on: { back: _vm.goBack } }),
            _c("h1", { staticClass: "cart__title" }, [_vm._v("Your Cart")]),
          ],
          1
        ),
        _c("div", { staticClass: "cart__container" }, [
          _c(
            "div",
            { staticClass: "cart__table" },
            [
              _c("cart-table", {
                attrs: { items: _vm.items || [] },
                on: { remove: _vm.handleRemove },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "cart__summary" },
            [
              _c("cart-summary", { attrs: { cart: _vm.cart } }),
              _c("cart-actions", {
                attrs: { error: _vm.error },
                on: { checkout: _vm.checkout },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm.pk
        ? _c("stripe-checkout", {
            ref: "sessionRef",
            attrs: {
              pk: _vm.pk,
              "session-id": _vm.session,
              successUrl: _vm.successUrl,
              cancelUrl: _vm.cancelUrl,
            },
          })
        : _vm._e(),
      _c("cart-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }