var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cart-summary" }, [
    _c("p", { staticClass: "cart-summary__label" }, [_vm._v("Subtotal")]),
    _c("div", { staticClass: "cart-summary__price" }, [
      _vm._v(_vm._s(_vm.formattedTotal)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }