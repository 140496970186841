var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cart-item", on: { click: _vm.goToItem } }, [
    _c("div", {
      staticClass: "cart-item__thumbnail",
      style: { backgroundImage: "url(" + _vm.item.thumbnailUrl + ")" },
    }),
    _c("div", { staticClass: "cart-item__content" }, [
      _c("p", { staticClass: "cart-item__name" }, [
        _vm._v(_vm._s(_vm.item.name)),
      ]),
      _c("p", { staticClass: "cart-item__price" }, [
        _vm._v(_vm._s(_vm.formattedTotal)),
      ]),
    ]),
    _c("button", {
      staticClass: "cart-item__close",
      on: { click: _vm.handleCloseButtonClick },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }